import React from "react"
import { shape } from "prop-types"
import styled from "styled-components"
import Layout, { layoutProps } from "../components/Layout"
import Row from "../components/Layout/Content/Row"
import Card from "../components/Card"
import key from "../utils/key"
import BecomeMemberSmall from "../components/BecomeMember/Small"
import ListSpecial from "../components/ListSpecial"
import NewMembers from "../components/NewMembers"
import BottomArrow from "../components/Card/BottomArrow"
import { color } from "../components/_globalSettings/skin"
import { Heading6 } from "../components/_globalSettings/typography"
import { breakpoint } from "../components/_globalSettings/var"

const MembershipHeading = styled.div`
	display: flex;
	width: 100%;
	@media (max-width: ${breakpoint.default.to}px) {
		flex-direction: column;
	}
`

const MembershipHeadingWrap = styled.div`
	.card__bottom-text {
		color: ${color.main.text2};
		font-size: 14px;
		line-height: 110%;
		font-weight: 500;
		letter-spacing: 0.3em;
		text-transform: uppercase;
		text-align: center;
	}
	.card__bottom-arrow {
		position: absolute;
		bottom: -70px;
		left: calc(50% - 10px);
	}
	.bottom-arrow__wrap {
		text-align: center;
		margin-top: 105px;
		margin-bottom: 92px;
		@media (max-width: ${breakpoint.preMin.to}px) {
			margin-top: 76px;
		}
	}
`

const CardNumber = styled.div`
	h6 {
		color: ${color.main.primary1};
	}
	@media (max-width: ${breakpoint.default.to}px) {
		h6 {
			margin-bottom: 0;
		}
	}
`

const MembershipHelp = styled.div`
	display: flex;
	padding-top: 190px;
	padding-bottom: 258px;
	justify-content: space-between;
	.membershipHelp__single {
		width: 29.1%;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		padding-top: 100px;
		flex-direction: column;
		padding-bottom: 36px;
		.membershipHelp__single {
			width: 100%;
		}
	}
`

const NewMembersWrap = styled.div`
	display: flex;
	padding: 160px 0;
	@media (max-width: ${breakpoint.default.to}px) {
		flex-direction: column;
		padding: 100px 0 0 0;
	}
`

const Membership = ({ pageContext }) => {
	return (
		<Layout {...pageContext}>
			<Row>
				<MembershipHeadingWrap>
					<MembershipHeading>
						<Card type="membership" {...pageContext.banner} />
						<BecomeMemberSmall
							{...pageContext.becomeMember}
							modalHeadline={pageContext.logInForm.headline}
							formData={pageContext.logInForm.form}
						/>
					</MembershipHeading>
					<div className="bottom-arrow__wrap">
						<BottomArrow text="Proč se stát členem ASWA?" />
					</div>
				</MembershipHeadingWrap>
			</Row>
			<Row wrap="wrap" color="#F8F9FC">
				<MembershipHelp>
					{pageContext.help.cards.map((card, i) => (
						<div className="membershipHelp__single">
							<CardNumber>
								<Heading6>{`0${i + 1}`}</Heading6>
							</CardNumber>
							<Card
								type="membershipHelp"
								key={key(i, card.headline)}
								{...card}
							/>
						</div>
					))}
				</MembershipHelp>
			</Row>
			<Row colorLeft={color.main.primary1} colorRight="#fff">
				<NewMembersWrap>
					<NewMembers data={pageContext.subBanner} />
					<ListSpecial data={pageContext.listSpecial} />
				</NewMembersWrap>
			</Row>
		</Layout>
	)
}

Membership.propTypes = {
	pageContext: shape({
		...layoutProps,
	}).isRequired,
}

export default Membership
