import styled from "styled-components"
import { breakpoint } from "../_globalSettings/var"
import { typography } from "../_globalSettings/typography"

const StyledNewMembers = styled.div`
	display: flex;
	flex-direction: column;
	width: 50%;
	padding-right: 15.1%;
	color: #fff;
	h3 {
		color: #fff;
	}
	p {
		${typography.BodyXXL};
		margin-top: 39px;
		margin-bottom: 48px;
	}
	button,
	a {
		height: 62px;
		margin-top: 24px;
		text-align: center;
	}
	ul {
		counter-reset: section;
	}
	li {
		font-size: 20px;
		line-height: 160%;
		margin-bottom: 24px;
		list-style-type: none;
		padding-left: 64px;
		font-weight: 500;
		&::before {
			counter-increment: section;
			content: counters(section, ".") " ";
			border: 2px solid #fff;
			border-radius: 50%;
			width: 40px;
			height: 40px;
			position: absolute;
			left: 51px;
			text-align: center;
			padding-top: 1px;
			font-size: 24px;
		}
	}
	@media (max-width: ${breakpoint.default.to}px) {
		width: 100%;
		padding-right: 0;
		padding-bottom: 100px;
		li::before {
			left: 17px;
		}
		h3 {
			font-size: 33px;
		}
	}
	@media (max-width: ${breakpoint.postMin.to}px) {
		li {
			font-size: 16px;
		}
	}
`

export default StyledNewMembers
