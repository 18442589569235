import React from "react"
import { string, array } from "prop-types"
import skin from "../_globalSettings/skin"
import { Heading3 } from "../_globalSettings/typography"
import StyledNewMembers from "./style"
import Button from "../Link/Button"

const NewMembers = ({ data }) => {
	return (
		<StyledNewMembers>
			<Heading3>{data.headline}</Heading3>
			<p>{data.content}</p>
			<ul>
				{data.steps.map(step => (
					<li>{step}</li>
				))}
			</ul>
			<Button
				href="https://docs.google.com/forms/d/e/1FAIpQLSekjFdepyHvgasNrnzidE3rVzkyBtDHjG7AQNwL_qkzsYwRAA/viewform"
				skin={skin.blueInverse}
			>
				Vyplnit dotazník
			</Button>
		</StyledNewMembers>
	)
}

export const NewMembersProps = {
	headline: string,
	content: string,
	steps: array,
}

NewMembers.propTypes = {
	...NewMembersProps,
}

export default NewMembers
