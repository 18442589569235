import styled from "styled-components"
import { breakpoint } from "../_globalSettings/var"
import { typography } from "../_globalSettings/typography"
import { color } from "../_globalSettings/skin"

const StyledListSpecial = styled.div`
	width: 50%;
	padding-left: 14.4%;
	position: relative;
	.special-list__check {
		position: relative;
		z-index: 1;
	}
	p {
		margin-top: 40px;
		margin-bottom: 48px;
		${typography.BodyXXL};
		color: ${color.main.text2};
	}
	li {
		position: relative;
		list-style-type: none;
		padding-left: 64px;
		font-size: 20px;
		line-height: 160%;
		color: ${color.main.text1};
		margin-bottom: 40px;
		font-weight: 500;
		&::before {
			content: url("/css/check3.svg");
			width: 40px;
			height: 40px;
			position: absolute;
			left: -2px;
			top: -2px;
		}
	}
	@media (max-width: ${breakpoint.default.to}px) {
		width: 100%;
		padding-top: 100px;
		padding-left: 0;
		padding-bottom: 60px;
		h3 {
			padding-bottom: 0;
		}
		.special-list__white-bg {
			background: #fff;
			height: 100%;
			width: calc(100% + 32px);
			position: absolute;
			top: 0px;
			left: -16px;
		}
	}
	@media (max-width: ${breakpoint.postMin.to}px) {
		li {
			font-size: 16px;
			padding-left: 50px;
		}
		li:before {
			top: -6px;
		}
	}
`

export default StyledListSpecial
