import React from "react"
import { string } from "prop-types"
import Arrow from "../../images/icons/arrow-down.svg"

const BottomArrow = ({ text }) => {
	return (
		<>
			<span className="card__bottom-text">{text}</span>
			<img
				src={Arrow}
				alt="Šipka"
				width="20"
				height="131"
				className="card__bottom-arrow"
			/>
		</>
	)
}

export const BottomArrowProps = {
	text: string.isRequired,
}

BottomArrow.propTypes = {
	...BottomArrowProps,
}

export default BottomArrow
