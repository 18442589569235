import React from "react"
import { string, shape, arrayOf } from "prop-types"
import StyledListSpecial from "./style"
import { Heading3 } from "../_globalSettings/typography"

const ListSpecial = ({ data }) => {
	return (
		<StyledListSpecial>
			<div className="special-list__white-bg" />
			<div className="special-list__check">
				{data.headline && <Heading3>{data.headline}</Heading3>}
				<p>{data.description}</p>
				<ul>
					{data.points.map(point => (
						<li>{point}</li>
					))}
				</ul>
			</div>
		</StyledListSpecial>
	)
}

export const ListSpecialProps = {
	data: shape({
		check: shape({
			headline: string.isRequired,
			blocks: arrayOf(
				shape({
					headline: string.isRequired,
					points: arrayOf(string).isRequired,
				})
			),
			star: string,
		}).isRequired,
		uncheck: shape({
			headline: string.isRequired,
			blocks: arrayOf(
				shape({
					headline: string.isRequired,
					points: arrayOf(string).isRequired,
				})
			),
			star: string,
		}).isRequired,
	}),
}

ListSpecial.propTypes = {
	...ListSpecialProps,
}

export default ListSpecial
