import React from "react"
import { string, shape, node } from "prop-types"
import skin from "../../_globalSettings/skin"
import Button from "../../Link/Button"
import StyledBecomeMember from "./style"
import { Heading4 } from "../../_globalSettings/typography"

const BecomeMember = ({ priceText }) => {
	return (
		<StyledBecomeMember>
			<div className="members__blue-bg" />
			<div className="members__content">
				<Heading4>Stát se členem ASWA</Heading4>
				<span className="member__price">{priceText}</span>
				<Button
					href="https://docs.google.com/forms/d/e/1FAIpQLSekjFdepyHvgasNrnzidE3rVzkyBtDHjG7AQNwL_qkzsYwRAA/viewform"
					skin={skin.blueInverse}
				>
					Vyplnit dotazník
				</Button>
			</div>
		</StyledBecomeMember>
	)
}

export const becomeMemberProps = {
	image: shape({
		publicURL: string,
	}),
	link: shape({
		url: string,
		label: string,
	}),
	children: node,
}

BecomeMember.propTypes = {
	...becomeMemberProps,
}

BecomeMember.defaultProps = {
	link: undefined,
	image: undefined,
	headline: undefined,
	content: undefined,
	children: undefined,
}

export default BecomeMember
