import styled from "styled-components"
import { breakpoint } from "../../_globalSettings/var"
import { color } from "../../_globalSettings/skin"

const StyledBecomeMember = styled.div`
	background-color: ${color.main.primary1};
	padding: 48px 64px;
	width: 46%;
	flex-shrink: 0;
	margin-top: 164px;
	align-self: start;
	text-align: center;
	position: relative;
	box-shadow: 0px 8px 24px rgba(37, 72, 255, 0.4);
	border-radius: 2px;
	h4 {
		color: #fff;
		margin-bottom: 25px;
	}
	.member__price {
		padding-left: 31px;
		color: #fff;
		position: relative;
		font-size: 21px;
		line-height: 30px;
		font-weight: 500;
		&:before {
			content: "";
			width: 20px;
			height: 20px;
			background-repeat: no-repeat;
			background-image: url("/css/tag.svg");
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	button,
	a {
		height: 62px;
		width: 100%;
		margin-top: 25px;
		text-align: center;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		width: 100%;
		margin-top: 64px;
	}
	@media (max-width: ${breakpoint.preMin.to}px) {
		padding: 48px 0;
		.members__blue-bg {
			width: calc(100% + 32px);
			height: 100%;
			position: absolute;
			left: -16px;
			top: 0;
			background: ${color.main.primary1};
		}
		.members__content {
			position: relative;
			z-index: 1;
		}
	}
`

export default StyledBecomeMember
